import React, { useState, useEffect } from 'react'
import Spinner from './components/Spinner/Spinner'
import Navbar from './components/Navbar/Navbar'
import Hero from './components/Hero/Hero'
import About from './components/About/About'
import Creations from './components/Creations/Creations'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import './App.sass'
import db from './firebase'

const App = () => {
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})

  useEffect(() => {
    const fetchContent = async () => {
      const doc = await db.collection('content').doc('ShUNRLaXzwvSjOFDjoQB').get()
      setContent(doc.data())
      setLoading(false)
    }

    fetchContent()
  }, [])

  return (
    <>
      {loading ?
        <Spinner /> :
        <>
          <Navbar {...content.navbar} />
          <Hero {...content.hero} />
          <About {...content.about} />
          <Creations {...content.creations} />
          <Contact {...content.contact} />
          <Footer />
        </>
      }
    </>
  )
}

export default App
