import React, { useState } from 'react'
import logo from '../../assets/logo.svg'
import './Navbar.sass'

const Navbar = props => {
  const [menu, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!menu)
  const closeMenu = () => setMenu(false)

  const menuItems = props.menuItems.map((item, index) =>
    <span
      key={index}
      className="navbar-item custom-navbar-item"
    >
      <a
        className="has-text-black-ter"
        href={item.link}
        onClick={closeMenu}
      >
        {item.title}
      </a>
    </span>
  )

  const socialNetworks = props.socialNetworks.map((item, index) =>
    <span
      key={index}
      className="navbar-item"
    >
      <a
        className="has-text-black-ter"
        href={item.link}
        target="_blank"
        rel='noreferrer noopener'
        onClick={closeMenu}
      >
        <i className={item.icon} />
      </a>
    </span>
  )

  return (
    <nav className="navbar is-shadowless is-fixed-top">
      <div className="container">
        <div className="navbar-brand">
          <a
            className="navbar-item"
            href="#hero"
            onClick={closeMenu}
          >
            <img
              src={logo}
              alt="Logo"
            />
          </a>
          <div
            className={`navbar-burger${menu ? ' is-active' : ''}`}
            onClick={toggleMenu}
          >
            <span />
            <span />
            <span />
          </div>

        </div>
        <div className={`navbar-menu ${menu ? 'is-active' : ''}`}>
          <div className="navbar-start" />
          <div className="navbar-end">
            {menuItems}
            {socialNetworks}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
