import React from 'react'
import './About.sass'

const About = props => (
  <section
    id="about"
    className="section has-background-white-ter"
  >
    <div className="container">
      <div className="columns is-vcentered">
        <div className="column">
          <img
            src={props.image}
            alt="Jean Monnet"
          />
        </div>
        <div className="column is-two-thirds">
          <h1 className="title is-size-2 has-text-weight-semibold">
            {props.aboutTitle}
          </h1>
          <p>
            {props.aboutDescription}
          </p>
          <h2 className="subtitle is-size-4 has-text-weight-semibold">
            {props.generalTitle}
          </h2>
          <p>
            {props.generalDescription}
          </p>
          <a
            className="button is-dark is-medium is-rounded"
            href="#creations"
          >
            {props.buttonText}
          </a>
        </div>
      </div>
    </div>
  </section>
)

export default About
