import React, { useState } from 'react'
import './Creations.sass'

const Creations = props => {
  const [modal, setModal] = useState(false)
  const [creation, setCreation] = useState({})
  const toggleModal = () => setModal(!modal)

  const showCreation = item => {
    toggleModal()
    setCreation({ ...item })
  }

  document.addEventListener('keydown', event => {
    if (modal && event.keyCode === 27) setModal(false)
  })

  const creations = props.creations.map((item, index) =>
    <div
      key={index}
      className="column is-12-mobile is-6-tablet is-4-desktop has-text-centered"
      onClick={() => showCreation(item)}
    >
      <div>
        <h2 className="subtitle is-size-4 has-text-weight-semibold has-text-white">
          {item.title}
        </h2>
        <img
          src={item.image}
          alt={item.title}
        />
      </div>
    </div >
  )

  return (
    <section
      id="creations"
      className="section has-background-dark"
    >
      <div className="container">
        <h1 className="title is-size-2 has-text-weight-semibold has-text-white has-text-centered">
          {props.title}
        </h1>
        <div className="columns is-multiline">
          {creations}
        </div>
        <p className="has-text-white has-text-centered">
          <a
            className="button is-black is-medium is-rounded is-inverted is-outlined"
            href="#contact"
          >
            {props.buttonText}
          </a>
        </p>
      </div>
      <div className={`modal${modal ? ' is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="container has-background-white">
            <div className="columns is-multiline">
              <div className="column is-12-touch is-6-desktop">
                <figure className="image is-1by1">
                  <img
                    src={creation.image}
                    alt={creation.title}
                  />
                </figure>
              </div>
              <div className="column is-12-touch is-6-desktop">
                <h2 className="subtitle is-size-3 has-text-weight-semibold">
                  {creation.title}
                </h2>
                <p>
                  {creation.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" onClick={toggleModal} />
      </div>
    </section>
  )
}

export default Creations
