import firebase from '@firebase/app'
import '@firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyBh-tQXbU-YUVZtFRsWjvnFJ_xCi0_ZE_E',
  authDomain: 'creationsbois-8c86a.firebaseapp.com',
  databaseURL: 'https://creationsbois-8c86a.firebaseio.com',
  projectId: 'creationsbois-8c86a',
  storageBucket: 'creationsbois-8c86a.appspot.com',
  messagingSenderId: '322220030775',
  appId: '1:322220030775:web:60e977c1f630b7142a38b2',
  measurementId: 'G-4Y80Z8117P'
})

const db = firebase.firestore()
db.enablePersistence()

export default db
