import React, { useEffect } from 'react'
import L from 'leaflet'
import customMarker from '../../assets/marker.svg'
import './Contact.sass'

const Contact = props => {
  useEffect(() => {
    const initMap = () => {
      const map = L.map(document.querySelector('.map'), {
        center: [
          props.latlng.latitude,
          props.latlng.longitude
        ],
        zoom: 14,
        dragging: false,
        doubleClickZoom: false,
        scrollWheelZoom: false
      })

      map.addLayer(
        new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          minZoom: 7,
          maxZoom: 19
        })
      )

      const marker = L.marker([props.latlng.latitude, props.latlng.longitude], {
        icon: L.icon({
          iconUrl: customMarker,
          iconSize: [32, 32]
        })
      }).addTo(map)

      marker.on('click', () => {
        L
          .popup()
          .setLatLng([props.latlng.latitude, props.latlng.longitude])
          .setContent(
            `<b>${props.contacts[1].title}</b><br />
            ${props.contacts[1].description}<br />
            <a target="_blank" href="https://maps.google.com?daddr=${[props.latlng.latitude, props.latlng.longitude].join()}">${props.directions}</a>`
          )
          .openOn(map)
      })
    }

    initMap()
  }, [props.contacts, props.directions, props.latlng.latitude, props.latlng.longitude])

  const contacts = props.contacts.map((item, index) => (
    <li key={index}>
      <div className="media">
        <div className="media-left">
          <i className={`is-size-4 ${item.icon}`} />
        </div>
        <div className="media-content is-clipped">
          <p className="title is-size-6">
            {item.title}
          </p>
          <p className="subtitle is-size-6">
            <a
              className="has-text-black-ter"
              href={item.link ? item.link : null}
              target="_blank"
              rel="noreferrer noopener"
            >
              {item.description}
            </a>
          </p>
        </div>
      </div>
    </li>
  ))

  return (
    <section
      id="contact"
      className="section has-background-white-ter"
    >
      <div className="container">
        <h1 className="title is-size-1 has-text-centered has-text-weight-semibold">
          {props.title}
        </h1>
        <div className="columns">
          <div className="column">
            <ul>
              {contacts}
            </ul>
          </div>
          <div className="column is-two-thirds">
            <div className="map" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
