import React from 'react'
import './Footer.sass'

const Footer = () => (
  <footer className="footer has-background-black-ter">
    <div className="container">
      <div className="content has-text-centered has-text-white">
        <p>
          Website made with <i className="fa fa-heart" /> by{' '}
          <a
            href="mailto:amiraiouaz@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <ins>Amir Aiouaz</ins>
          </a>
            .
          </p>
      </div>
    </div>
  </footer>
)

export default Footer
