import React from 'react'
import './Hero.sass'

const Hero = props => (
  <section
    id="hero"
    className="hero is-fullheight"
    style={{ background: `url('${props.image}') center / cover` }}
  >
    <div className="hero-body">
      <div className="container has-text-centered">
        <div className="columns is-centered">
          <div className="column is-8-tablet">
            <h1 className="title has-text-white is-size-1">
              {props.title}
            </h1>
            <p className="has-text-white is-size-3">
              {props.description}
            </p>
            <a
              className="button is-black is-medium is-rounded is-inverted is-outlined"
              href="#about"
            >
              {props.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Hero
